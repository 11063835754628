export const INIT_toggleItem = () => {
    window.addEventListener('click', event => {
        const trigger = event.target;
        const dataset = trigger.dataset.toggleItem;

        if (dataset) {
            const data = JSON.parse(dataset);

            if (data) {
                const wrapper = document.getElementById(data.wrapper);
                const target = document.getElementById(data.target);
                const ccc = document.getElementById('ccc');

                if (wrapper && target) {
                    const items = [...wrapper.querySelectorAll('[data-toggle-item-target]')];

                    if (items) {
                        items.forEach(item => {
                            if (item !== target) {
                                item.classList.remove('is-open');
                            }
                        });
                    }

                    trigger.classList.toggle('is-active');
                    target.classList.toggle('is-open');

                    // Show / Hide Cookie Controller and Chat when necessary
                    if (target.classList.contains('is-open')) {
                        window.Tawk_API.hideWidget();
                        ccc.style.display = 'none';
                    } else {
                        window.Tawk_API.showWidget();
                        ccc.style.display = 'block';
                    }
                }
            }
        } else {
            if (!trigger.closest('[data-toggle-item-target]')) {
                const items = [...document.querySelectorAll('[data-toggle-item-target]')];

                if (items) {
                    items.forEach(item => {
                        item.classList.remove('is-open');
                    });
                }
            }
        }
    });
};
