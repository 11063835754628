export const INIT_polyfillHas = () => {
    window.onload = () => {
        const isSelectorSupported = (selector) => {
            try {
                document.querySelector(selector);
                return true;
            } catch (error) {
                return false;
            }
        }

        if (!isSelectorSupported(':has(body)')) {

            // Always
            polyfillHasAlways();

            let observerAlways = new MutationObserver((mutations) => {
                polyfillHasAlways();
            });

            observerAlways.observe(document, {
                attributes: false,
                childList: true,
                characterData: false,
                subtree: true,
            });

            // Listener
            const elemsListener = [...document.querySelectorAll('[polyfill-has="listener"]')];

            if (elemsListener) {
                elemsListener.forEach(elem => {
                    const listenerClass = elem.getAttribute('polyfill-has-class');

                    if (elem.hasAttribute('polyfill-has-start')) {
                        elem.classList.add('polyfill-has');
                    }

                    let listener = elem.closest(elem.getAttribute('polyfill-has-listener'));

                    if (!listener) {
                        listener = elem.querySelector(elem.getAttribute('polyfill-has-listener'));
                    }

                    if (!listener) {
                        listener = document.querySelector(elem.getAttribute('polyfill-has-listener'));
                    }

                    if (listener) {
                        if (listener.classList.contains(listenerClass)) {
                            if (elem.hasAttribute('polyfill-has-start')) {
                                elem.classList.remove('polyfill-has');
                            } else {
                                elem.classList.add('polyfill-has');
                            }
                        }

                        let observer = new MutationObserver((mutations) => {
                            mutations.forEach(event => {
                                if (event.target.classList.contains(listenerClass)) {
                                    if (elem.hasAttribute('polyfill-has-start')) {
                                        elem.classList.remove('polyfill-has');
                                    } else {
                                        elem.classList.add('polyfill-has');
                                    }
                                } else {
                                    if (elem.hasAttribute('polyfill-has-start')) {
                                        elem.classList.add('polyfill-has');
                                    } else {
                                        elem.classList.remove('polyfill-has');
                                    }
                                }
                            });
                        });

                        observer.observe(listener, { attributeFilter: ['class'] });
                    }
                });
            }

            // Focus
            const elemsFocus = [...document.querySelectorAll('[polyfill-has="focus"]')];

            if (elemsFocus) {
                elemsFocus.forEach(elem => {
                    const focus = elem.querySelector('[polyfill-has-focus]');

                    focus.addEventListener('focus', () => {
                        elem.classList.add('polyfill-has');
                    });

                    focus.addEventListener('blur', () => {
                        elem.classList.remove('polyfill-has');
                    });
                });
            }
        }

        function polyfillHasAlways() {

            // Generic
            const elems = [...document.querySelectorAll('[polyfill-has="always"]')];

            if (elems) {
                elems.forEach(elem => {
                    elem.classList.add('polyfill-has');
                });
            }

            // Typography
            const elemsTypo = [...document.querySelectorAll('.g-typo')];

            if (elemsTypo) {
                elemsTypo.forEach(elem => {
                    const ps = [...elem.querySelectorAll('p:not(:first-child')];

                    if (ps) {
                        ps.forEach(p => {
                            if (p.querySelector('.btn')) {
                                p.classList.add('polyfill-has--btn');
                            }

                            if (p.querySelector('img')) {
                                p.classList.add('polyfill-has--img');
                            }
                        });
                    }
                });
            }
        }
    }
};
