import { scrollToElem } from './support';

export const INIT_goTo = () => {
    window.addEventListener('click', event => {
        const goto = event.target.dataset.goto;

        if (goto === 'top') {
            event.target.blur();
            scrollToElem(document.documentElement);
        } else {
            const elem = document.getElementById(goto);

            if (elem) {
                event.target.blur();
                scrollToElem(elem);
            }
        }
    });
};
