export default class StickyHeader {
    constructor() {
        const body = document.querySelector('body');

        if (window.scrollY > 0) {
            body.classList.add('is-sticky-header');
            body.classList.add('sticky-down');
        }

        window.addEventListener('scroll', function () {
            if (window.scrollY > 0) {
                body.classList.add('is-sticky-header');
            } else {
                body.classList.remove('is-sticky-header');
            }

            if (this.oldScroll > this.scrollY) {
                body.classList.add('sticky-up');
                body.classList.remove('sticky-down');
            } else {
                body.classList.add('sticky-down');
                body.classList.remove('sticky-up');
            }

            this.oldScroll = this.scrollY;
        });
    }
}