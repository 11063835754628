import { loadScript } from './support';

export const INIT_Maps = () => {
    const gMapsApiKey = document.querySelector("meta[name='google_maps_api_key']").getAttribute('content');
    const gMaps = [...document.getElementsByClassName('js-maps')];

    if (gMaps.length) {
        loadScript(`https://maps.google.com/maps/api/js?key=${gMapsApiKey}&callback=Function.prototype`).then(() => {
            gMaps.forEach((map) => {
                const mapID = map.id;
                const markers = JSON.parse(map.dataset.markers);
                const assetsPath = map.dataset.assets;
                const zoom = parseInt(map.dataset.zoom);

                if (mapID && markers && assetsPath) {
                    INIT_map(mapID, markers, assetsPath, zoom);
                }
            });
        });
    }

    const INIT_map = (mapID, markers, assetsPath, zoom) => {

        // Markers
        let markersAll = [];

        // Icons
        const icons = {
            'default': {
                url: `${assetsPath}marker-orange.svg`,
                scaledSize: new google.maps.Size(48, 48),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 48),
            },
        };

        // Bounds
        let bounds = new google.maps.LatLngBounds();

        // Create Map
        let map = new google.maps.Map(document.getElementById(mapID), {
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            options: {
                gestureHandling: 'greedy'
            },
            scrollwheel: false,
        });

        // Add Marker
        const addMarker = (mrkr) => {

            // Create Marker
            let marker = new google.maps.Marker({
                position: new google.maps.LatLng(mrkr.lat, mrkr.lng),
                map: map,
                icon: icons['default'],
            });

            markersAll.push(marker);

            bounds.extend(marker.position);
        }

        markers.forEach((marker) => {
            addMarker(marker);
        });

        // Center Map
        centerMap(map, markers, bounds, zoom);
    }

    const centerMap = (map, markers, bounds, zoom = 12) => {
        if (markers.length == 1) {
            map.setCenter(new google.maps.LatLng(markers[0].lat, markers[0].lng));
            map.setZoom(zoom);
        } else {
            map.fitBounds(bounds);
        }
    }
};
