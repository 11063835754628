export const INIT_showMore = () => {
    window.addEventListener('click', event => {
        const showMoreStep = event.target.dataset.gShowMoreStep;

        if (showMoreStep && showMoreStep > 0) {
            const showMoreWrapper = event.target.dataset.gShowMoreWrapper;

            if (showMoreWrapper) {
                const wrapper = document.getElementById(showMoreWrapper);

                if (wrapper) {
                    const items = [...wrapper.querySelectorAll('[data-g-show-more="false"]')];

                    if (items.length) {
                        let counter = 0;

                        event.target.blur();

                        items.forEach(item => {
                            if (counter < showMoreStep) {
                                counter++;
                                item.dataset.gShowMore = true;
                            }
                        });

                        const moreItems = [...wrapper.querySelectorAll('[data-g-show-more="false"]')];

                        if (!moreItems.length) {
                            event.target.disabled = true;
                        }
                    }
                }
            }
        }
    });
};
