/*
 * scrollToElem
 */

export const scrollToElem = (elem, offset = 0, delay = 1) => {
    setTimeout(() => {
        const y = elem.getBoundingClientRect().top + window.pageYOffset - offset;

        window.scrollTo({
            top : y,
            behavior : 'smooth',
        });
    }, delay);
};

/*
 * elemSiblings
 */

export const elemSiblings = n => [...n.parentElement.children].filter(c=>c!=n);

/*
 * nextSibling
 */

export const getNextSibling = (elem, selector) => {
    let sibling = elem.nextElementSibling;

    if (!selector) {
        return sibling;
    }

    while (sibling) {
        if (sibling.matches(selector)) {
            return sibling;
        }

        sibling = sibling.nextElementSibling;
    }
};

/*
 * lockScroll
 */

export function lockScroll() {
    document.querySelector('html').classList.add('has-cc-lock');
}

/*
 * unlockScroll
 */

export function unlockScroll() {
    document.querySelector('html').classList.remove('has-cc-lock');
}

/*
 * slideUp
 */

export const slideUp = (target, duration = 300) => {
    target.classList.add('is-animating');

    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;

    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');

        target.classList.remove('is-animating');
    }, duration);
}

/*
 * slideDown
 */

export const slideDown = (target, duration = 300) => {
    target.classList.add('is-animating');

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;

    if (display === 'none')
        display = 'block';

    target.style.display = display;

    let height = target.offsetHeight;

    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');

    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');

        target.classList.remove('is-animating');
    }, duration);
}

/*
 * loadScript
 */

export async function loadScript(src) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');

        script.setAttribute('src', src);
        document.body.appendChild(script);

        script.onload = resolve;
        script.onerror = reject;
    });
}

/*
 * debounceResize
 */

export function debounceResize(func) {
    let timer;

    return (event) => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(func,100,event);
    };
}

/*
 * isScrolledIntoView
 */

export function isScrolledIntoView(el) {
    let rect = el.getBoundingClientRect();
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);

    return isVisible;
}
