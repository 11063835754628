import { slideUp, slideDown } from './support';

export const INIT_accordion = () => {
    window.addEventListener('click', (event) => {
        const target = event.target;

        if (target.dataset.gritAccordionTrigger) {
            target.blur();

            const accordion = target.closest('[data-grit-accordion]');
            const itemID = target.dataset.gritAccordionTrigger;
            const thisItem = document.getElementById(itemID);

            // State of triggers
            [...accordion.querySelectorAll('[data-grit-accordion-trigger]')].forEach(btn => {
                if (btn !== target) {
                    if (accordion.dataset.gritAccordionCloseAll === 'true' && btn.classList.contains('is-active')) {
                        btn.classList.remove('is-active');
                    }
                } else {
                    if (!btn.classList.contains('is-active')) {
                        btn.classList.add('is-active');
                    } else {
                        btn.classList.remove('is-active');
                    }
                }
            });

            // State of cloned triggers
            [...accordion.querySelectorAll('[data-grit-accordion-trigger]')].forEach(btn => {
                if (btn !== target && btn.dataset.gritAccordionTrigger === itemID) {
                    if (target.classList.contains('is-active')) {
                        btn.classList.add('is-active');
                    } else {
                        btn.classList.remove('is-active');
                    }
                }
            });

            // State of accordion items
            [...accordion.querySelectorAll('[data-grit-accordion-item]')].forEach(item => {
                if (item.id !== thisItem.id) {
                    if (accordion.dataset.gritAccordionCloseAll === 'true' && item.classList.contains('is-active')) {
                        item.classList.remove('is-active');
                        slideUp(item);
                    }
                } else {
                    if (!item.classList.contains('is-active')) {
                        item.classList.add('is-active');
                        slideDown(item);
                    } else {
                        item.classList.remove('is-active');
                        slideUp(item);
                    }
                }
            });
        }

        // Expand / Collapse All
        if (target.dataset.gritAccordionToggleAll) {
            target.blur();

            const accordionID = target.dataset.gritAccordionToggleAll;
            const accordionStatus = target.dataset.gritAccordionToggleStatus;

            if (accordionID) {
                const accordion = document.getElementById(accordionID);

                if (accordion) {
                    if (accordionStatus === 'open') {
                        target.dataset.gritAccordionToggleStatus = 'close';

                        [...accordion.querySelectorAll('[data-grit-accordion-trigger]')].forEach(btn => {
                            btn.classList.remove('is-active');
                        });

                        [...accordion.querySelectorAll('[data-grit-accordion-item]')].forEach(item => {
                            item.classList.remove('is-active');
                            slideUp(item);
                        });
                    } else {
                        target.dataset.gritAccordionToggleStatus = 'open';

                        [...accordion.querySelectorAll('[data-grit-accordion-trigger]')].forEach(btn => {
                            btn.classList.add('is-active');
                        });

                        [...accordion.querySelectorAll('[data-grit-accordion-item]')].forEach(item => {
                            item.classList.add('is-active');
                            slideDown(item);
                        });
                    }
                }
            }
        }
    });
}
