/*
 * Essential imports
 */

import Alpine from 'alpinejs';
import 'picturefill';
import 'lazysizes';

/*
 * Essential fixes
 */

// Preload and No-JS
setTimeout(() => {
    document.documentElement.classList.remove('preload');
    document.documentElement.classList.remove('no-js');
}, 300);

let resizeId;

window.addEventListener('resize', () => {
    clearTimeout(resizeId);
    document.documentElement.classList.add('preload');
    resizeId = setTimeout(() => {
        document.documentElement.classList.remove('preload');
    }, 300);
});

// Fix NodeList.forEach in IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/*
 * Function imports
 */

import { INIT_accordion } from './functions/accordion';
import { INIT_forms } from './functions/forms';
import { INIT_goTo } from './functions/goto';
import { INIT_Maps } from'./functions/maps';
import { INIT_modals } from './functions/modals';
import { INIT_showMore } from "./functions/show-more";
import { INIT_sliders } from "./functions/sliders";
import { INIT_toggleItem } from "./functions/toggle-item";

INIT_accordion();
INIT_forms();
INIT_goTo();
INIT_Maps();
INIT_modals();
INIT_showMore();
INIT_sliders();
INIT_toggleItem();

/*
 * FlashMessage
 */

import { FlashMessage } from './grit-form-handler';
export const msg = new FlashMessage('#flash-message', 2000);

/*
 * Polyfills
 */

import { INIT_polyfillHas } from './functions/polyfill-has';
INIT_polyfillHas();

/*
 * StickyHeader
 */

import StickyHeader from './functions/sticky-header';
new StickyHeader();

/*
 * Archive filters
 */

window.addEventListener('change', (event) => {
    const target = event.target;

    if (target.id === 'form-archive-terms') {
        document.body.classList.add('is-loading');
        window.location.href = target.value;
    }

    if (target.id === 'form-archive-past') {
        let searchParams = new URLSearchParams(window.location.search)

        if (target.checked) {
            searchParams.set('past', true);
        } else {
            searchParams.set('past', false);
        }

        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        history.pushState(null, '', newRelativePathQuery);

        document.body.classList.add('is-loading');
        window.location.reload();
    }
});

/*
 * FAQs Filters
 */

const faqsItems = [...document.querySelectorAll('[data-faqs-category]')];
const allBtns = [...document.querySelectorAll('[data-faqs-filter]')];
const btnAll = document.querySelector('[data-faqs-filter="all"]');
let filters = [];

window.addEventListener('click', event => {
    const trigger = event.target;

    if (trigger.classList.contains('g-faqs--filters--btn')) {
        trigger.blur();

        if (trigger === btnAll) {
            faqsItems.forEach((item) => {
                item.classList.remove('is-hidden');
            });

            allBtns.forEach((btn) => {
                btn.classList.remove('is-active');
            });

            trigger.classList.add('is-active');
        } else {
            trigger.classList.toggle('is-active');

            const filter = trigger.dataset.faqsFilter;

            if (trigger.classList.contains('is-active')) {
                if (!filters.includes(filter)) {
                    filters.push(filter);
                }
            } else {
                filters = filters.filter((item) => {
                    return item !== filter;
                });
            }

            if (filters.length) {
                btnAll.classList.remove('is-active');

                if (faqsItems.length) {
                    faqsItems.forEach((item) => {
                        const categories = item.dataset.faqsCategory.split(',');

                        if (categories.length) {
                            const intersection = filters.filter((n) => { return categories.indexOf(n) !== -1; });

                            if (intersection.length) {
                                item.classList.remove('is-hidden');
                            } else {
                                item.classList.add('is-hidden');
                            }
                        }
                    });
                }
            } else {
                btnAll.classList.add('is-active');
            }
        }
    }
});

/*
 * GetInTouch
 */

import GetInTouch from './functions/get-in-touch';
new GetInTouch();

/*
 * Payment Form
 */

const formPayment = document.getElementById('form_payment');

if (formPayment) {
    const paymentAmount = formPayment.querySelector('[name="amount"]');
    const paymentMethods = formPayment.querySelectorAll('[name="payment_method"]');
    const paymentTot = formPayment.querySelector('.payment--form--total');

    if (paymentAmount && paymentMethods && paymentTot) {
        paymentAmount.addEventListener('change', updateTotal);

        [...paymentMethods].forEach((method) => {
            method.addEventListener('change', updateTotal);
        });

        function updateTotal() {
            if (paymentAmount.value) {
                let checked = false;
                const tot = parseFloat(paymentAmount.value.replace(/,/g, '.'));

                [...paymentMethods].forEach((method) => {
                    if (method.checked) {
                        checked = method.value;
                    }
                });

                paymentTot.innerHTML = tot.toFixed(2);
            }
        }
    }
}

Alpine.start();